<template>
    <div class="bg-blue-700 text-white py-2 px-4 flex justify-between items-center h-16">

            <svg class="w-12 h-12 absolute top-0 left-0 mt-2 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <circle cx="32" cy="32" r="30" fill="#008CDE" />
                <text x="50%" y="50%" text-anchor="middle" alignment-baseline="middle" font-family="Arial, sans-serif"
                    font-size="24" fill="white" font-weight="bold">
                    W⛬
                </text>
            </svg>


        <div class="text-lg ml-12 font-bold">{{ $t('title') }}</div>


        <div v-if="!connected" class="top-bar-content">
            <button @click="connectWallet" class="rounded-xl p-2 bg-slate-300 text-blue-500">Connect Wallet</button>
        </div>
        <div v-else class="top-bar-content">
            <p>address: {{ userAddress }}</p>
            <button @click="disconnectWallet" class="rounded-xl p-2 bg-slate-400 text-white-200">Disconnect</button>
        </div>

        <div class="relative">
            <button @click="isOpen = !isOpen" class="flex items-center space-x-1">
                <span>{{ selectedLanguage }}</span>
                <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" />
                </svg>
            </button>
            <ul v-if="isOpen" class="absolute mt-1 w-20 bg-white border rounded shadow">
                <li v-for="language in languages" :key="language.id" @click="selectLanguage(language)"
                    class="px-2 py-1 cursor-pointer hover:bg-gray-100 text-gray-700">
                    {{ language.name }}
                </li>
            </ul>
        </div>
    </div>
</template>
  
<script>
import Web3 from 'web3';
import { mapGetters } from 'vuex';
export default {
    name: 'TopBar',
    setup() {
    },
    data() {
        return {
            connected: false,
            isOpen: false,
            selectedLanguage: 'English', // 默认选中的语言
            languages: [
                { id: 1, name: 'English' },
                { id: 2, name: '中文' },
                // 其他语言...
            ],
        };
    },

    computed: {
        ...mapGetters(['isConnected', 'userAddress']),
    },

    methods: {

        async connectWallet() {
            try {
                if (window.ethereum) {
                    await window.ethereum.request({ method: 'eth_requestAccounts' });
                    const web3 = new Web3(window.ethereum);
                    const accounts = await web3.eth.getAccounts();
                    this.userAddress = accounts[0];
                    this.connected = true;

                    this.$store.commit('setConnected', true);
                    this.$store.commit('setUserAddress', accounts[0]);
                } else {
                    console.error('Metamask extension not found');
                }
            } catch (error) {
                console.error('Error connecting wallet:', error);
            }
        },
        disconnectWallet() {
            this.connected = false;
            this.userAddress = '';

            this.$store.commit('setConnected', false);
            this.$store.commit('setUserAddress', '');
        },

        selectLanguage(language) {
            this.selectedLanguage = language.name;
            this.isOpen = false;
            // 在这里可以触发语言切换的逻辑
            if (language.id === 1) {
                this.$i18n.locale = 'en'

            }
            if (language.id === 2) {
                this.$i18n.locale = 'chs'

            }

        },
    }
};
</script>
  
  