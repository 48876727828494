<template>
  <div class="h-screen bg-gray-900">
    <div class="flex flex-row">
      <input class="w-full bg-gray-500" v-model="videoUrl" placeholder="输入视频链接" />
      <select v-model="preUrl">
        <option v-for="option in prePlayList" :key="option.value" :value="option.value">{{ option.label }}
        </option>
      </select>
      <button class="bg-gray-700  text-white px-20" @click="playVideo" value="">
        <svg class="w-6 h-6 mr-2 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M8 5v14l11-7z"></path>
        </svg>
        Play
      </button>
    </div>
    <iframe id="palybox" src="/ws" allowtransparency="true" frameborder="0" scrolling="no" allowfullscreen="true"
      width="100%" height="640px">
    </iframe>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      videoUrl: '',
      preUrl:''
,      prePlayList:[
        {label:"接口1",value:"https://jx.playerjy.com/?url="},
        {label:"接口2",value:"https://jx.m3u8.tv/jiexi/?url="},
        {label:"接口3",value:"https://jx.xmflv.com/?url="},
        {label:"接口4",value:"https://jx.jsonplayer.com/player/?url="}
      ]
    };
  },
  methods: {


    playVideo() {
      // var prePlay1 = "http://www.82190555.com/index/qqvod.php?url=";

      // Reload the video source to trigger playback
      var play = document.getElementById("palybox"); //获取播放窗口位置
      //var prePlay2 = "https://jx.playerjy.com/?url=";
      //var prePlay7 = "https://jx.m3u8.tv/jiexi/?url=";

      //play.src = prePlay7 + this.videoUrl; //接口赋值

      play.src = this.preUrl+ this.videoUrl;
      console.log(play.src);
      //ajax数据传递

      var tittext = document.getElementById("tittext");
      //1,create ajax核心对象：
      var xhr = getxhr();
      //2,以post的方式与服务器建立连接；
      xhr.open("post", "data/title.php", true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      //3,发送一个http请求:
      xhr.send("titurl=" + this.videoUrl);
      console.log(xhr.readyState);
      //获取服务器状态码
      xhr.onreadystatechange = function () {
        console.log(xhr.readyState)
        console.log(xhr.status)
        if (xhr.readyState == 4 && xhr.status == 200) {
          tittext.innerHTML = xhr.responseText; //获取服务器响应数据
        }
      }

      function getxhr() {
        var xhr = null;
        if (window.XMLHttpRequest) {
          xhr = new XMLHttpRequest();
        }
        return xhr;
      }

    }
  }

};

</script>
  
