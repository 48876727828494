<template>
    <div class="p-4 bg-gray-900 text-white rounded">
        <div class="flex items-center">
            <div class="w-3 h-3 rounded-full bg-red-500 mr-2"></div>
            <div class="w-3 h-3 rounded-full bg-yellow-500 mr-2"></div>
            <div class="w-3 h-3 rounded-full bg-green-500"></div>
        </div>
    
        <pre ref="output" class="mt-2 p-2 border border-gray-700 rounded h-72 overflow-auto transition terminal" v-on:scroll="onScroll">
                <code class="block text-sm font-mono leading-tight">
                    <span v-html="output" class="text-blue-400"></span>
                </code>
        </pre>
        <input v-model="command" @keydown.enter="executeCommand"
            class="w-full mt-2 p-2 bg-gray-800 text-white border rounded" placeholder="Command...">

    </div>
</template>
  
<script>
export default {
    data() {
        return {
            command: "",
            output: "",
            isScrolledToBottom: true, // 添加一个标记，记录是否已滚动到底部
        }

    },
    methods: {
         // 将新内容添加到滚动区域，并滚动到底部（如果已滚动到底部）
        appendOutput(newContent) {
            this.output += newContent;
            if (this.isScrolledToBottom) {
                this.$nextTick(() => {
                this.scrollToBottom();
                });
            }
        },

        async executeCommand() {
            // invoke command to deploy server
            const response = await fetch(`http://localhost:5678/execute`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ command: this.command })
            });

            const data = await response.json();
           // console.log(data.output)
           this.output  = this.output+  `<br>${this.command}<br>Executing ${this.command}...`; 

             // 等待异步命令执行完成
            await this.simulateAsyncCommandExecution();

            this.output += `<br>Output of ${this.command}<br>`;
            this.appendOutput(data.output);

        },

        async simulateAsyncCommandExecution() {
            // 模拟异步命令执行
            await new Promise((resolve) => setTimeout(resolve, 500));
            this.scrollToBottom();
        },

        scrollToBottom() {
            const outputEl = this.$refs.output;
            outputEl.scrollTo({
                top: outputEl.scrollHeight,
                behavior: "smooth",
            });
            // const outputEl = this.$refs.output;
            // const scrollHeight = outputEl.scrollHeight;
            // const clientHeight = outputEl.clientHeight;

            // let startTime = null;

            // const animation = (timestamp) => {
            //     if (!startTime) {
            //         startTime = timestamp;
            //     }

            //     const progress = timestamp - startTime;
            //     const distance = scrollHeight - clientHeight;
            //     const duration = 1000; // 1 秒钟

            //     const scrollAmount = easeInOutCubic(progress, 0, distance, duration);

            //     outputEl.scrollTop = scrollAmount;

            //     if (progress < duration) {
            //         requestAnimationFrame(animation);
            //     }
            // };

            // requestAnimationFrame(animation);
        },

        // 监听滚动事件，判断是否已滚动到底部
        onScroll() {
            const outputEl = this.$refs.output;
            this.isScrolledToBottom =
            outputEl.scrollTop + outputEl.clientHeight === outputEl.scrollHeight;
        },

    }
};

// use cubic ease-in-out
// function easeInOutCubic(t, b, c, d) {
//   t /= d / 2;
//   if (t < 1) return (c / 2) * t * t * t + b;
//   t -= 2;
//   return (c / 2) * (t * t * t + 2) + b;
// }
</script>
  

<style>
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* 添加滚动特效 */
.transition {
    transition: scroll-behavior 1s ease-in-out;
}

.terminal {
  border: 1px solid #ccc;
  overflow-y: scroll;
}

</style>