import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n';
import router from './router'
import store from './store';
import "@/assets/css/tailwind.css"


const i18n = createI18n({
    locale: 'en', // 默认语言
    fallbackLocale: 'en',
    messages: {
      en:   require('./i18n/en.json'), // 语言文件路径
      chs:  require('./i18n/chs.json'),
    //   cht:  require('./i18n/cht.json'),
    //   jp:   require('./i18n/jp.json')
      // 可以添加更多语言文件
    },
  })

const app =createApp(App)
app.config.productionTip=false
app.use(i18n)
app.use(store)
app.use(router)
app.mount('#app')

