import { createRouter, createWebHistory } from 'vue-router'

import Home from './Home.vue'; // 导入要跳转的组件
import ClaimNFT from './components/ClaimNFT.vue'
import ClaimToken from './components/ClaimToken.vue'
import DeployToken from './components/DeployToken'
import ConfigWeb3 from './components/ConfigWeb3.vue'
import CmdConsole from './components/CmdConsole.vue'
import VideoPlayer from './components/VideoPlayer.vue'
import WaitSee from './components/WaitSee.vue'

import Step1 from './components/Step1.vue'

import DesignPage from './components/DesignPage.vue'
import PreviewPage from './components/PreviewPage.vue'


// const router = VueRouter.createRouter({
//     // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
//     history: VueRouter.createWebHashHistory(),
//     routes, // `routes: routes` 的缩写
//   })

export default new createRouter({
  routes: [
    {
      path: '/',
      component: DesignPage,
      children: [
        { path: '', redirect: 'step1' }, // Redirect to step1 by default
        { path: 'step1', component: Step1 },
        { path: 'step2', component: DeployToken },
        { path: 'step3', component: ConfigWeb3 },
        { path: 'step4', component: CmdConsole },

      ],

    },
    { path: '/home', component: Home },
    { path: '/claimtoken', component: ClaimToken },
    { path: '/claimnft', component: ClaimNFT },
    { path: '/config', component: ConfigWeb3 },
    { path: '/deploy', component: DeployToken },
    // { path: '/cmd', component: CmdConsole },
    { path: '/play', component: VideoPlayer },
    { path: '/ws', component: WaitSee },
    { path: '/preview', component: PreviewPage },
    // { path: '/step1', component: Step1 },
    // { path: '/step2', component: ConfigWeb3 },

  ],
  history: createWebHistory()
})