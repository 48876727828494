<template>
  <div class="social-links-container d-flex justify-content-end p-2">
    <a style="text-decoration: none; color: white" href="https://discord.gg/9tgSW2Mrwg" target="_blank"><img
        class="social-links" alt="" src="./assets/images/discord_svg.svg"></a>
    <a style="text-decoration: none; color: white" href="https://twitter.com/JDDMain" target="_blank"><img
        class="social-links" alt="" src="./assets/images/twitter-brands.svg"></a>
    <a style="text-decoration: none; color: white" href="https://www.instagram.com/JDD_official/" target="_blank"><img
        class="social-links" alt="" src="./assets//images/instagram-brands.svg"></a>
    <a style="text-decoration: none; color: white" href="https://t.me/JDDme" target="_blank"><img class="social-links"
        alt="" src="./assets//images/telegram-brands.svg"></a>
    <a style="text-decoration: none; color: white" href="https://www.youtube.com/channel/UCiY_a_6N8wSQMS6S3T7zzSw"
      target="_blank"><img class="social-links" alt="" src="./assets//images/youtube-brands.svg"></a>
    <a style="text-decoration: none; color: white" href="https://www.reddit.com/r/JDDtoken" target="_blank"><img
        class="social-links" alt="" src="./assets//images/reddit_svg.svg"></a>
  </div>

  <div>
    <div class="container-center-horizontal">
      <div class="x1 screen">
        <img alt="about token" class="vector-7aGJld"
          src="data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMjk2NyIgaGVpZ2h0PSI1MDYiIHZpZXdCb3g9IjAgMCAyOTY3IDUwNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNjY3OTY5IDE4OS44NjRMMTI0LjI1MSAyMzEuODY2QzI0Ny44MzUgMjc0LjY1NiA0OTUuMDAxIDM1Ny40NzYgNzQyLjE2OCAzMzcuMTY1Qzk4OS4zMzUgMzE2LjA2NiAxMjM2LjUgMTg5Ljg2NCAxNDgzLjY3IDE2OC43NjVDMTczMC44MyAxNDguNDU0IDE5NzggMjMxLjI3NCAyMjI1LjE3IDIyMS40MTVDMjQ3Mi4zMyAyMTEuNTU1IDI3MTkuNSAxMDUuMDczIDI4NDMuMDggNTMuMjExN0wyOTY2LjY3IDAuNTYyMDEyVjUwNS4zNjhIMjg0My4wOEMyNzE5LjUgNTA1LjM2OCAyNDcyLjMzIDUwNS4zNjggMjIyNS4xNyA1MDUuMzY4QzE5NzggNTA1LjM2OCAxNzMwLjgzIDUwNS4zNjggMTQ4My42NyA1MDUuMzY4QzEyMzYuNSA1MDUuMzY4IDk4OS4zMzUgNTA1LjM2OCA3NDIuMTY4IDUwNS4zNjhDNDk1LjAwMSA1MDUuMzY4IDI0Ny44MzUgNTA1LjM2OCAxMjQuMjUxIDUwNS4zNjhIMC42Njc5NjlWMTg5Ljg2NFoiIGZpbGw9InVybCgjcGFpbnQwX3JhZGlhbCkiLz4KPGRlZnM+CjxyYWRpYWxHcmFkaWVudCBpZD0icGFpbnQwX3JhZGlhbCIgY3g9IjAiIGN5PSIwIiByPSIxIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgZ3JhZGllbnRUcmFuc2Zvcm09InRyYW5zbGF0ZSgxMTE0LjY3IC00MzIuNTI2KSByb3RhdGUoOTApIHNjYWxlKDkwMi45NDUgNTMwNS4yNykiPgo8c3RvcCBzdG9wLWNvbG9yPSIjQjg4MEZGIiBzdG9wLW9wYWNpdHk9IjAiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRjhFREZGIi8+CjwvcmFkaWFsR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==">
        <div id="page" class="p-4 pt-5 page-container" style="padding-top: 2vw;">
          <!-- home section -->
          <div class="row p-4">
            <div class="col-6">
              <div>
                <h1 class="communityH2">
                  JDD Dao! Community Token
                </h1>
                <p class="communityP">
                  Just hold $JDD in your wallet and you will get more on
                  each transaction the protocol automatically distributes
                  rewards to holders as well as auto-burn liquidity.

                </p>
                <div class="flex col py-3">
                  <div class="py-2">
                    <button style="background-color: #606bbc; color: white" class="pd-2 my-btn px-3 py-3">
                      LiveChart
                    </button>
                  </div>
                  <div class="px-2 py-2">
                    <button class=" my-btn px-3 py-3">
                      BuyNow
                    </button>
                  </div>
                  <div class="py-2">
                    <button class="my-btn px-3 py-3">
                      ClaimToken
                    </button>
                  </div>
                  <div class="px-2 py-2">
                    <button class="my-btn px-3 py-3">
                      ClaimNFT
                    </button>
                  </div>
                </div>
                <div class="flex col">
                  <input type="text" class="flex w-4/5 border border-1 rounded border-blue-500"
                    value="0x18359CF655A444204e46F286eDC445C9D30fFc54" id="copyField" readonly="">
                  <div class="px-1">
                    <button id="copy" @click="change"
                      class="flex items-center justify-center rounded-lg bg-blue-700 hover:bg-blue-500 text-white font-bold py-2 px-2">
                      <img class="img px-1" style="color: black" src="./assets/images/copy.png">
                      {{ btntext }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <img class="banner-cat" alt="" src="./assets/images/about-token.png">
            </div>
          </div>

          <div style="padding: 6vw;"></div>


          <!--Tokenmic Auditing Section -->
          <div class="flex flex-row p-4 pt-5 community-driven-container">
            <div class="col-6 textSpace">
              <div>
                <div class="d-flex justify-content-between no-wrap">
                  <span class="arrow-container" style="background-color: black;"><img class="img"
                      style="background-color: black;" alt="right-arrow-img" src="./assets/images/right-arrow.svg"></span>

                  <div>
                    <h1 class="communityH2">Token Name</h1>
                  </div>
                </div>
                <div>
                  <p class="communityP">JieDuDao $JDD$
                  </p>
                  <svg class="rc-progress-line2" viewBox="0 0 100 0.5" preserveAspectRatio="none">
                    <path class="rc-progress-line-trail" d="M 0.25,0.25
          L 99.75,0.25" stroke-linecap="round" stroke="transparent" stroke-width="1" fill-opacity="0"></path>
                    <path class="rc-progress-line-path" d="M 0.25,0.25
          L 99.75,0.25" stroke-linecap="round" stroke="#7F86C9" stroke-width="0.5" fill-opacity="0"
                      style="stroke-dasharray: 723.365px, 100px; stroke-dashoffset: 0px; transition: stroke-dashoffset 0s ease 0s, stroke-dasharray 0s ease 0s, stroke linear 0s;">
                    </path>
                  </svg>
                </div>
              </div>

              <div class="pt-4">
                <div class="d-flex justify-content-between no-wrap">
                  <span class="arrow-container" style="background-color: black;"><img class="img"
                      style="background-color: black;" alt="right-arrow-img" src="./assets/images/right-arrow.svg"></span>
                  <div>
                    <h1 class="communityH2">Token Name </h1>
                  </div>
                </div>
                <div>
                  <p class="communityP">100000000000000 </p>
                  <svg class="rc-progress-line2" viewBox="0 0 100 0.5" preserveAspectRatio="none">
                    <path class="rc-progress-line-trail" d="M 0.25,0.25
          L 99.75,0.25" stroke-linecap="round" stroke="transparent" stroke-width="1" fill-opacity="0"></path>
                    <path class="rc-progress-line-path" d="M 0.25,0.25
          L 99.75,0.25" stroke-linecap="round" stroke="#7F86C9" stroke-width="0.5" fill-opacity="0"
                      style="stroke-dasharray: 723.365px, 100px; stroke-dashoffset: 0px; transition: stroke-dashoffset 0s ease 0s, stroke-dasharray 0s ease 0s, stroke linear 0s;">
                    </path>
                  </svg>
                </div>
              </div>

              <div class="pt-4">
                <div class="d-flex justify-content-between no-wrap">
                  <span class="arrow-container" style="background-color: black;"><img class="img"
                      style="background-color: black;" alt="right-arrow-img" src="./assets/images/right-arrow.svg"></span>
                  <div>
                    <h1 class="communityH2">Contract Address </h1>
                  </div>
                </div>
                <div>
                  <p class="communityP">JieDuDao $JDD$</p>
                  <svg class="rc-progress-line2" viewBox="0 0 100 0.5" preserveAspectRatio="none">
                    <path class="rc-progress-line-trail" d="M 0.25,0.25
          L 99.75,0.25" stroke-linecap="round" stroke="transparent" stroke-width="1" fill-opacity="0"></path>
                    <path class="rc-progress-line-path" d="M 0.25,0.25
          L 99.75,0.25" stroke-linecap="round" stroke="#7F86C9" stroke-width="0.5" fill-opacity="0"
                      style="stroke-dasharray: 723.365px, 100px; stroke-dashoffset: 0px; transition: stroke-dashoffset 0s ease 0s, stroke-dasharray 0s ease 0s, stroke linear 0s;">
                    </path>
                  </svg>
                </div>
              </div>

              <div class="pt-4">
                <div class="d-flex justify-content-between no-wrap">
                  <a style="text-decoration: none; display: flex; flex-direction: row;" target="_blank"
                    href="https://drive.google.com/file/d/1UmVlgePcApaMIAKB6Xoc0TXtocMcV4Ek/view">
                    <span class="arrow-container"><img class="img" alt="right-arrow-img"
                        src="./assets/images/Vector.svg"></span>
                  </a>
                  <div>
                    <h1 class="communityH2">Tax</h1>
                  </div>

                </div>
                <div>
                  <p class="communityP">
                    0% </p>
                  <svg class="rc-progress-line2" viewBox="0 0 100 0.5" preserveAspectRatio="none">
                    <path class="rc-progress-line-trail" d="M 0.25,0.25
          L 99.75,0.25" stroke-linecap="round" stroke="transparent" stroke-width="1" fill-opacity="0"></path>
                    <path class="rc-progress-line-path" d="M 0.25,0.25
          L 99.75,0.25" stroke-linecap="round" stroke="#7F86C9" stroke-width="0.5" fill-opacity="0"
                      style="stroke-dasharray: 723.365px, 100px; stroke-dashoffset: 0px; transition: stroke-dashoffset 0s ease 0s, stroke-dasharray 0s ease 0s, stroke linear 0s;">
                    </path>
                  </svg>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="image-container">
                <img class="img" src="./assets/images/JDD.png">
              </div>
            </div>
          </div>

          <div style="padding: 6vw;"></div>

          <div class="token-section-container">
            <div class="flex flex-row" style="z-index: 999;">
              <div class="col-4">
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <a style="text-decoration: none;"
                    href="https://bscscan.com/token/0x18359CF655A444204e46F286eDC445C9D30fFc54" target="_blank"><img
                      class="img" alt="" src="./assets/images/pathor.png" style="width: 4vw;"></a>
                  <h2 class="communityH2">8247</h2>
                  <p class="communityP2">Holders</p>
                </div>
              </div>
              <div class="col-4">
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <a style="text-decoration: none;"
                    href="https://bscscan.com/token/0x18359CF655A444204e46F286eDC445C9D30fFc54?a=0x000000000000000000000000000000000000dead"
                    target="_blank"><img class="img" alt="" src="./assets/images/pathor.png" style="width: 4vw;"></a>
                  <h2 class="communityH2">
                    6,321,351,206</h2>
                  <p class="communityP2">Tokens Burned</p>
                </div>
              </div>
              <div class="col-4">
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <a style="text-decoration: none;"
                    href="https://bscscan.com/token/0x18359CF655A444204e46F286eDC445C9D30fFc54" target="_blank"><img
                      class="img" alt="" src="./assets/images/pathor.png" style="width: 4vw;"></a>
                  <h2 class="communityH2">27,7K
                    USD</h2>
                  <p class="communityP2">Charity Donations</p>
                </div>
              </div>
            </div>
          </div>
          <!-- end new about section-->

          <div style="padding: 3vw;"></div>

          <div id="roadmap" class="p-4 pb-5 roadmap-section">
            <h1 style="font-family: 'Space Grotesk', sans-serif; font-weight: 700;" class="text-center p-5">Roadmap
            </h1>            
          
            <div class="timeline-line"></div>
            <div class="flex items-center justify-between relative">
              <div v-for="(milestone, index) in milestones" :key="index" :class="[relative,'w-1/'+milestones.length]">
                <div class="milestone-circle">STEP{{ index +1 }}</div>
                <div :class="['roadmap-content', index % 2 === 0 ? 'even' : 'odd']">
                  <p class="text-center">{{ milestone.stage }}</p>
                  <p class="text-center">{{ milestone.description }}</p>
                </div>
              </div>
            </div>

            <!-- <h1 style="font-family: 'Space Grotesk', sans-serif; font-weight: 700;" class="text-center p-5">Roadmap
            </h1>
            <div class="d-flex no-wrap justify-content-center align-items-center">
              <div style="width: 20%;">
                <div class="d-flex justify-content-center align-items-center" style="position: relative;"><img class="img"
                    alt="" src="./assets/images/roadmap-btn.svg" style="width: 20vw;">
                  <div class="d-flex align-items-center justify-content-center up-btn-text"
                    style="position: absolute; top: 0px; left: 0px; width: 100%; height: 80%;">
                    <p class="stage">M1 </p>
                  </div>
                </div>
              </div>
              <div style="width: 20%;">
                <p class="text-center roadmap-content">Website
                  Re-design<br>CoinmarketCap and Coingecko
                  Contract Swap<br>Marketing and Social Campaigns<br>Huge Community Growth on all the Socials</p>
              </div>
              <div style="width: 20%;">
                <div class="d-flex justify-content-center align-items-center" style="position: relative;"><img class="img"
                    alt="" src="./assets/images/roadmap-btn.svg" style="width: 20vw;">
                  <div class="d-flex align-items-center justify-content-center up-btn-text"
                    style="position: absolute; top: 0px; left: 0px; width: 100%; height: 80%;">
                    <p class="stage">M2 </p>
                  </div>
                </div>
              </div>
              <div style="width: 20%;">
                <p class="text-center roadmap-content">Exchange
                  Listings<br>Payment for merch with
                  JDD<br>App Development</p>
              </div>
              <div style="width: 20%;">
                <div class="d-flex justify-content-center align-items-center" style="position: relative;"><img class="img"
                    alt="" src="./assets/images/roadmap-btn.svg" style="width: 20vw;">
                  <div class="d-flex align-items-center justify-content-center up-btn-text"
                    style="position: absolute; top: 0px; left: 0px; width: 100%; height: 80%;">
                    <p class="stage">M3 </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex no-wrap">
              <div style="width: 20%;">
                <div style="width: 100%;">
                  <div class="dots">
                    <p style="height: 2vw; width: 2vw;left: 50%; margin-left: -0.5vw; position: absolute; top: -0.7vw; ">
                      <img class="img" style="height: 2vw; width: 2vw;" src="./assets/images/Group7009.svg">
                    </p>
                  </div>
                </div>
              </div>
              <div style="width: 20%;">
                <div style="width: 100%;">
                  <div class="dots">
                    <p style="height: 2vw; width: 2vw;left: 50%; margin-left: -0.5vw; position: absolute; top: -0.7vw; ">
                      <img class="img" style="height: 2vw; width: 2vw;" src="./assets/images/Group7009.svg">
                    </p>
                  </div>
                </div>
              </div>
              <div style="width: 20%;">
                <div style="width: 100%;">
                  <div class="dots">
                    <p style="height: 4vw; width: 4vw;left: 50%; margin-left: -0.5vw; position: absolute; top: -1.5vw; ">
                      <img class="img" style="height: 4vw; width: 4vw;" src="./assets/images/logo-jdd.png">
                    </p>
                  </div>
                </div>
              </div>
              <div style="width: 20%;">
                <div style="width: 100%;">
                  <div class="dots">
                    <p style="height: 2vw; width: 2vw;left: 50%; margin-left: -0.5vw; position: absolute; top: -0.7vw; ">
                      <img class="img" style="height: 2vw; width: 2vw;" src="./assets/images/Group7009.svg">
                    </p>
                  </div>
                </div>
              </div>
              <div style="width: 20%;">
                <div style="width: 100%;">
                  <div class="dots">
                    <p style="height: 2vw; width: 2vw;left: 50%; margin-left: -0.5vw; position: absolute; top: -0.7vw">
                      <img class="img" style="height: 2vw; width: 2vw;" src="./assets/images/Group7009.svg">
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class=" d-flex no-wrap justify-content-center align-items-center" style="padding: 2vw;">
              <div style="width: 20%;">
                <p class="text-center roadmap-content2">
                  Finished Airdrop<br>Relaunch on new
                  contract (V2)<br>Liquidity Locked for 265 Years<br>New and Improved Tokenomics<br>Partnership with
                  Able Child Africa</p>
              </div>
              <div style="width: 20%;">
                <div class="d-flex justify-content-center align-items-center" style="position: relative;"><img class="img"
                    alt="" src="./assets/images/roadmap-down-btn.svg" style="width: 20vw;">
                  <div class="d-flex align-items-center justify-content-center bottom-btn-text"
                    style="position: absolute; top: 0px; left: 0px; width: 100%;">
                    <p style="font-family: 'Space Grotesk', sans-serif; font-weight: 700;font-size: 1.2vw; color: white;">
                     M4</p>
                  </div>
                </div>
              </div>
              <div style="width: 20%;">
                <p class="text-center roadmap-content2">
                  Higher Marketing and Social
                  Campaigns<br>Big Charity donations<br>JDD Merchandise<br>New Charity
                  Partnerships</p>
              </div>
              <div style="width: 20%;">
                <div class="d-flex justify-content-center align-items-center" style="position: relative;"><img class="img"
                    alt="" src="./assets/images/roadmap-down-btn.svg" style="width: 20vw;">
                  <div class="d-flex align-items-center justify-content-center bottom-btn-text"
                    style="position: absolute; top: 0px; left: 0px; width: 100%;">
                    <p style="font-family: 'Space Grotesk', sans-serif; font-weight: 700;font-size: 1.2vw; color: white;">
                      M5
                    </p>
                  </div>
                </div>
              </div>
              <div style="width: 20%;">
                <p class="text-center roadmap-content2">
                  Higher Marketing and Social
                  Campaigns<br>Big Charity donations<br>JDD Merchandise<br>New Charity
                  Partnerships</p>
              </div>
            </div> -->
          </div>

          <div id="howtobuy" class="howtobuy">
            <h1 style="font-family: 'Space Grotesk', sans-serif; font-weight: 700; color: black;" class="text-center p-3">
              Buy JDD Step by Step
            </h1>
            <div>
              1) Download MetaMask or Trust Wallet Applications

              <br>

              2) Purchase Binance Tokens (BNB) and send them to your MetaMask or Trust Wallet.
              <br>

              3) On the home page of JDD.me click on “Buy Now” button. This will open a Dapp Exchange Called
              “PancakeSwap”, as alternative add https://pancakeswap.finance/ to your dapp browser on trust wallet or
              your browser on metamask wallet
              <br>
              <!-- <iframe style="width: 50vw; height: 28vw;" src="https://www.youtube.com/embed/fxbbocXEddg?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe> -->
              4) A popup will open, click the check box “I understand”, this will load JDD into PancakeSwap,
              alternative add contract mannualy: 0x18359cf655a444204e46f286edc445c9d30ffc54

              <br>

              5) Make sure your MetaMask Wallet or Trust Wallet Is synced with PancakeSwap.
              <br>


              6) Set the amount of JDD you would like to purchase, set the slippage to 12% and press Swap!
              <br>


              7) Enjoy your trip to the moon with Dogge!
              <br>

            </div>
            <a href="https://pancakeswap.finance/swap?outputCurrency=0x18359cf655a444204e46f286edc445c9d30ffc54"
              target="_blank">
              <button class="border-2 bg-blue-500 text-white rounded-lg px-2 py-2">Buy Now</button>
            </a>
            <a style="text-decoration: none;" href="https://poocoin.app/tokens/0x18359cf655a444204e46f286edc445c9d30ffc54"
              target="_blank">
              <button class="border-2 bg-blue-500 text-white rounded-lg px-2 py-2">Buy now via Poocoin</button>
            </a>
          </div>


        </div>
      </div>

    </div>
    
    <div id="footer"
            style="background-image: url('./assets/images/roadmap-section-bg.svg'); background-color: rgb(98, 107, 189);">
            <div class="flex flex-row pt-5" style="color:white">
              <div class="col-6"><img class="img" alt="" src="./assets/images/logo-jdd.png" style="width: 5vw;">
              </div>
              <div class="col-2">
                <h4
                  style="font-family: 'Space Grotesk', sans-serif; font-weight: 700; font-size: 1.8vw; margin-bottom: 0.6vw;">
                  Community</h4>
                <a style="font-family: 'Space Grotesk', sans-serif; font-weight: 500; font-size: 1vw; margin-bottom: 0.6vw; opacity: 70%; text-decoration: none; color: #d5d2d9;"
                  href="https://discord.gg/8yDeZAZH" target="_blank">
                  <p style="font-size: 1.4vw; margin-bottom: 0px;">Discord</p>
                </a>
                <a style="font-family: 'Space Grotesk', sans-serif; font-weight: 500; font-size: 1vw; margin-bottom: 0.6vw; opacity: 70%; text-decoration: none; color: #d5d2d9;"
                  href="https://www.reddit.com/r/JDDtoken" target="_blank">
                  <p style="font-size: 1.4vw; margin-bottom: 0px;">Reddit</p>
                </a>
                <a style="font-family: 'Space Grotesk', sans-serif; font-weight: 500; font-size: 1vw; margin-bottom: 0.6vw; opacity: 70%; text-decoration: none; color: #d5d2d9;"
                  href="https://t.me/JDDme" target="_blank">
                  <p style="font-size: 1.4vw; margin-bottom: 0px;">Telegram</p>
                </a>
              </div>
              <div class="col-2">
                <h4
                  style="font-family: 'Space Grotesk', sans-serif; font-weight: 700; font-size: 1.8vw; margin-bottom: 0.6vw;">
                  Social</h4>
                <a style="font-family: 'Space Grotesk', sans-serif; font-weight: 500; font-size: 1vw; margin-bottom: 0.6vw; opacity: 70%; text-decoration: none; color: #d5d2d9;"
                  href="https://twitter.com/JDDMain" target="_blank">
                  <p style="font-size: 1.4vw; margin-bottom: 0px;">Twitter</p>
                </a>
                <a style="font-family: 'Space Grotesk', sans-serif; font-weight: 500; font-size: 1vw; margin-bottom: 0.6vw; opacity: 70%; text-decoration: none; color: #d5d2d9;"
                  href="https://www.instagram.com/JDD_official/" target="_blank">
                  <p style="font-size: 1.4vw; margin-bottom: 0px;">Instagram</p>
                </a>
              </div>
              <div class="col-2">
                <h4
                  style="font-family: 'Space Grotesk', sans-serif; font-weight: 700; font-size: 1.8vw; margin-bottom: 0.6vw;">
                  Resources</h4>
                <a style="font-family: 'Space Grotesk', sans-serif; font-weight: 500; font-size: 1vw; margin-bottom: 0.6vw; opacity: 70%; text-decoration: none; color: #d5d2d9;"
                  href="https://bscscan.com/address/0x18359CF655A444204e46F286eDC445C9D30fFc54" target="_blank">
                  <p style="font-size: 1.4vw; margin-bottom: 0px;">Contract</p>
                </a>
                <a style="font-family: 'Space Grotesk', sans-serif; font-weight: 500; font-size: 1vw; margin-bottom: 0.6vw; opacity: 70%; text-decoration: none; color: #d5d2d9;"
                  href="https://drive.google.com/file/d/1-_Jj_aFmEPDH-3oSnTNzxKIyViqGLjJt/view" target="_blank">
                  <p style="font-size: 1.4vw; margin-bottom: 0px;">WhitePaper</p>
                </a>
                <a style="font-family: 'Space Grotesk', sans-serif; font-weight: 500; font-size: 1vw; margin-bottom: 0.6vw; opacity: 70%; text-decoration: none; color: #d5d2d9;"
                  href="https://t.me/JDDme" target="_blank">
                  <p style="font-size: 1.4vw; margin-bottom: 0px;">Support</p>
                </a>

              </div>
            </div>
            <div>
              <hr style="height: 1px; background-color: #d5d2d9; margin-top: 3vw; margin-bottom: 3vw;">
            </div>
            <div class="flex-row" style="padding-bottom: 3vw;">
              <div class="col-6">
                <p
                  style="text-align: left; font-size: 1.2vw; font-family: 'Space Grotesk', sans-serif; font-weight: 500; color: #d5d2d9;">
                  Copyright © 2023. All rights reserved by JDD</p>
              </div>
            </div>
    </div>
  </div>
</template>
  
  
<script>
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';

export default {
  name: 'HomeIndex',

  data() {
    return {
      currentLanguage: 'en',
      languages: {
        en: require('./lang/en.json'),
        jp: require('./lang/jp.json'),
        chs: require('./lang/chs.json'),
        cht: require('./lang/cht.json')
      },
      swiperOptions: {
        autoplay: true
      },
      btntext: '  Copy',
      contract: '0x18359CF655A444204e46F286eDC445C9D30fFc54',
      milestones: [
        { stage: 'M1', description: 'Website Re-design\nCoinmarketCap and Coingecko\nContract Swap\nMarketing and Social Campaigns\nHuge Community Growth on all the Socials' },
        { stage: 'M2', description: 'Website Re-design\nCoinmarketCap and Coingecko\nContract Swap\nMarketing and Social Campaigns\nHuge Community Growth on all the Socials' },
        { stage: 'M3', description: 'Website Re-design\nCoinmarketCap and Coingecko\nContract Swap\nMarketing and Social Campaigns\nHuge Community Growth on all the Socials' },
        { stage: 'M4', description: 'Website Re-design\nCoinmarketCap and Coingecko\nContract Swap\nMarketing and Social Campaigns\nHuge Community Growth on all the Socials' },
        { stage: 'M5', description: 'Website Re-design\nCoinmarketCap and Coingecko\nContract Swap\nMarketing and Social Campaigns\nHuge Community Growth on all the Socials' },

        // ... Other milestones
      ]
    };
  },
  mounted() {

    $(document).ready(() => {
      // jquery
    });
  },
  computed: {
    $t() {
      return (key) => this.languages[this.currentLanguage][key];
    }
  },
  methods: {
    change() {
      console.log(this.contract);
      navigator.clipboard.writeText(
        "0x18359CF655A444204e46F286eDC445C9D30fFc54"
      );
      //btn.onClick;
      this.btntext = "  Copied";
    },
    config() {
      this.$router.push()
    }
  }
};
</script>

<style scoped>@import './assets/css/global.css';</style>
