<template>
    <div class="flex items-center justify-center h-screen bg-gray-900">
      <div class="p-6 bg-white rounded-lg shadow-md">
        <div class="animate-pulse">
          <div class="h-16 bg-gray-300 rounded"></div>
          <div class="mt-4 h-4 bg-gray-200 rounded"></div>
          <div class="mt-2 h-3 bg-gray-200 rounded"></div>
        </div>
        <div>QQVideo,iQiyi,YouKu,Bilibili....<br>
        For learning only, not for commercial use    
        </div>   
        <div class="mt-6 flex justify-center">
          <div class="w-10 h-10 bg-gray-300 rounded-full animate-ping"></div>
        </div>
      </div>
    </div>
  </template>