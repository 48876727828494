<template>
    <div>
    <h2>Mint Token</h2>
    <div>
      <label for="recipient">Recipient Address:</label>
      <input v-model="recipient" type="text" id="recipient" />
    </div>
    <div>
      <label for="amount">Amount to Mint:</label>
      <input v-model.number="amount" type="number" id="amount" />
    </div>
    <button @click="mintTokens">Mint Tokens</button>
  </div> 
</template>

<script>
import { reactive } from "vue";
import Web3 from "web3";
import MyTokenContract from "../contracts/StudyToken.json"; // 替换为你的合约ABI文件路径

export default {
  setup() {
    const state = reactive({
      recipient: "",
      amount: 0,
      web3: null,
      myTokenContract: null,
      contractAddress: "0xa507D7d7110bA01bC1f917C4844D056d070C2F17" // 替换为你的合约地址
    });

    async function initializeWeb3() {
      if (typeof window.ethereum !== "undefined") {
        // 使用MetaMask提供的web3对象
        state.web3 = new Web3(window.ethereum);
        try {
          // 请求用户授权连接到MetaMask钱包
          await window.ethereum.request({ method: "eth_requestAccounts" });
        } catch (error) {
          console.error("User denied account access:", error);
        }
      } else {
        console.error("Please install MetaMask to use this application.");
      }
    }

    async function initializeContract() {
      if (state.web3) {
        state.myTokenContract = new state.web3.eth.Contract(
          MyTokenContract.abi,
          state.contractAddress
        );
      }
    }

    async function mintTokens() {
      if (state.web3 && state.myTokenContract) {
        try {
          const accounts = await state.web3.eth.getAccounts();
          console.log(accounts[0])
          const recipient = "0x4Ac241a04aa44ab66893A365CA892666AF3961Fd";
          const amount = 1;
          console.log(amount)
          //const vamount = Web3.utils.toWei(amount,'ether')
          
          //console.log('input amount is:'+Web3.utils.toWei(amount,'ether'));

          const result = await state.myTokenContract.methods
            .mint(recipient, amount)
            .send({ from: accounts[0] })
          console.log("Minting transaction hash:", result.transactionHash);
        } catch (error) {
          console.error("Error while minting tokens:", error);
        }
      } else {
        console.error("Web3 or contract not initialized.");
      }
    }

    initializeWeb3();
    initializeContract();

    return {
      ...state,
      mintTokens
    };
  }
};
</script>