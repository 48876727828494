<template>
    <div class="flex justify-center ">
        <div class="flex justify-center bg-gray-100 p-2 w-full h-screen">


            <form @submit.prevent="handleSubmit" class="rounded shadow-lg mb-6">
                <div>
                    <div class="flex justify-center">
                        <h1 class="text-xl font-semibold mb-4">Customize Your Website</h1>
                    </div>
                    <div class="flex">
                        <div class="flex-shrink-0 p-4">
                            <label class="block mb-2">Choose a style</label>
                            <input type="radio" v-model="selectedOption" value="option1" class="mr-2"> Option 1
                        </div>
                        <div class="flex-shrink-0 p-4">
                            <img src="../assets/images/style1.jpg" class="mb-4" style="max-width: 150px;">
                        </div>

                        <div class="flex-grow p-4">
                            <label class="block mb-2">Website Logo:</label>
                            <input type="file" accept="image/*" @change="handleLogoChange" class="mb-2">
                            <img :src="formData.logo" v-if="formData.logo" class="mb-4" style="max-width: 150px;">

                            <label class="block mb-2">Website Background Color:</label>
                            <input type="color" class="mb-2 rounded p-1" v-model="formData.backgroundColor">
                        </div>

                    </div>
                    <div class="flex justify-center">
                        <button
                            class="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
                            @click="goToNextStep">选好了就下一步</button>
                    </div>
                </div>
            </form>

        </div>

    </div>
</template>
  
<script>
export default {
    name: 'StepPage1',
    data() {
        return {
            formData: {
                logo: '', // Store the selected logo image
                title: '',
                backgroundColor: '#ffffff',
                // Other configuration data
            }
        };
    },
    mounted() {
        // Listen for route changes
        this.$router.afterEach((to, from) => {
            this.currentStep = to.path;
            this.fromStep =from.path;
            console.log("this.currentStep is:"+to.path)
            console.log("this.fromStep is:"+from.path)
        });
    },

    methods: {
        handleSubmit() {
            // Send data to backend API for processing
            // Handle the preview and download logic
        },
        handleLogoChange(event) {
            const file = event.target.files[0];
            if (file) {
                // Convert the selected image to base64 and set it to the logo property
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.formData.logo = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        goToPreviousStep() {
            this.$router.push(this.currentStep === 'step2' ? '/step1' : '/');
        },
        goToNextStep() {
            console.log("go to nextStep")
            this.$router.push('step1');
        }
    }
};
</script>
  
<style>
/* Add your custom styles here */
</style>
  