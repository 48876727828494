<template>
    <TopBar />

    <div class="bg-gray-200 rounded shadow-md w-full p-4">
        <!-- Tab Navigation -->
        <div class="mb-4">
            <router-link to="/step1" class="tab-link" :class="{ 'active-tab': currentStep === '/step1' }">Step
                1</router-link>
            <router-link to="/step2" class="tab-link" :class="{ 'active-tab': currentStep === '/step2' }">Step
                2</router-link>
            <router-link to="/step3" class="tab-link" :class="{ 'active-tab': currentStep === '/step3' }">Step
                3</router-link>
            <router-link to="/step4" class="tab-link" :class="{ 'active-tab': currentStep === '/step4' }">Step
                4</router-link>
        </div>

        <!-- Step Content -->
        <div v-if="currentStep === '/step1'">
            <p class="mt-2 p-2">第一步，选择一个Logo和背景基调.</p>
        </div>
        <div v-else-if="currentStep === '/step2'">
            <p class="mt-2 p-2">第二步，输入代币名称和发型总量，属于你的一个干净安全的合约就创建完成了，兼容ERC20协议的多种网络.</p>
        </div>
        <div v-else-if="currentStep === '/step3'">
            <p class="mt-2 p-2">第三步，给自己做上描述，和配上社交媒体，预览OK后，打包下载.</p>
        </div>
        <div v-else-if="currentStep === '/step4'">
            <p class="mt-2 p-2">第四步，科学家工具，明明白白是如何添加池子之后第一个抢先买的.</p>
        </div>
        <router-view></router-view>

    </div>

    <!-- <div class="flex">
        <div class="bg-gray-200 rounded shadow-md w-full">
            <div style="padding: 1vw;"></div>
            <div class="mb-4">
                <router-link to="/step1" class="tab-link" :class="{ 'active-tab': currentStep === '/step1' }">Step
                    1</router-link>
                <router-link to="/step2" class="tab-link" :class="{ 'active-tab': currentStep === '/step2' }">Step
                    2</router-link>
                <router-link to="/step3" class="tab-link" :class="{ 'active-tab': currentStep === '/step3' }">Step
                    3</router-link>
            </div>
        </div>
    </div> -->
</template>
  
<script>
import TopBar from './TopBar.vue';
import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            formData: {
                logo: '', // Store the selected logo image
                title: '',
                backgroundColor: '#ffffff',
                currentStep: this.$route.path
                // Other configuration data
            },
            currentStep: this.$route.path
        };
    },
    mounted() {
        // Listen for route changes
        this.$router.afterEach((to, from) => {
            this.currentStep = to.path;
            console.log(from)
        });
    },
    components: {
        TopBar
    },
    computed: {
        ...mapState(['walletConnected', 'userAddress'])
    },
    methods: {
        ...mapActions(['connectWallet']),

        handleSubmit() {
            // Send data to backend API for processing
            // Handle the preview and download logic
        },
        handleLogoChange(event) {
            const file = event.target.files[0];
            if (file) {
                // Convert the selected image to base64 and set it to the logo property
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.formData.logo = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        goToPreviousStep() {
            this.$router.push(this.currentStep === 'step2' ? '/step1' : '/');
        },
        goToNextStep() {
            this.$router.push(this.currentStep === 'step1' ? '/step2' : '/');
        }
    }
};
</script>
  
<style>
.tab-link {
    text-decoration: none;
    padding: 0.5rem 1rem;
    color: #333;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.tab-link:hover {
    background-color: #e0e0e0;
}

/* Active tab */
.active-tab {
    background-color: #4a90e2;
    color: white;
    border-color: #4a90e2;
}

.active-tab:hover {
    background-color: #357ac8;
}

/* Add your custom styles here */
</style>