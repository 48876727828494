import { createStore } from 'vuex';

export default createStore({
  state: {
    connected: false,
    userAddress: '',
  },
  mutations: {
    setConnected(state, value) {
      state.connected = value;
    },
    setUserAddress(state, address) {
      state.userAddress = address;
    },
  },

  actions: {
    setConnection({ commit }, connected) {
      commit('setConnected', connected);
    },
    setAddress({ commit }, address) {
      commit('setUserAddress', address);
    },
  },
  
  getters: {
    isConnected(state) {
      return state.connected;
    },
    userAddress(state) {
      return state.userAddress;
    },
  },
});
