<template>
    <div class="p-6 bg-white shadow-md rounded">
      <h2 class="text-lg font-semibold mb-4">Website Preview</h2>
      <div :style="previewStyles" class="border border-gray-300 p-4">
        <h3 :style="titleStyles">{{ formData.title }}</h3>
        <!-- Other preview content based on configuration -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      formData: Object, // Pass the form data as a prop
    },
    computed: {
      previewStyles() {
        return {
          backgroundColor: this.formData.backgroundColor,
          // Other computed styles
        };
      },
      titleStyles() {
        return {
          fontSize: '24px',
          // Other computed styles for title
        };
      },
    },
  };
  </script>
  
  <style>
  /* Add your custom styles here */
  </style>
  