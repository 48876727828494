<template>
    <div class="flex">
        <!-- Left Layout Attributr Editor -->
        <div class="w-2/3 bg-gray-100 p-2">
            <div class="p-4 space-y-4">
                <fieldset class="border p-4">
                    <legend class="text-lg font-semibold">Socail Links</legend>
                    <div class="mb-2 flex flex-col">
                        <div v-if="selectedIconIndex !== null">
                            <div><img class="w-10 h-10 " :src="socialIcons[selectedIconIndex].image"
                                    :alt="socialIcons[selectedIconIndex].name" /></div>
                            <div><input v-model="selectedImageUrl" class="w-full border rounded p-2" placeholder="输入URL"
                                    @input="updateImageUrl(selectedIconIndex)" /></div>
                        </div>
                    </div>
                </fieldset>


                <!-- Group 1: Basic Information -->
                <fieldset class="border p-4">
                    <legend class="text-lg font-semibold">About Information</legend>
                    <div class="mb-2">
                        <label for="name" class="block">Name:</label>
                        <input type="text" id="name" v-model="contract.name" class="w-full border rounded p-2" />
                    </div>
                    <div>
                        <label for="description" class="block">Description:</label>
                        <textarea id="description" v-model="contract.description"
                            class="w-full border rounded p-2"></textarea>
                    </div>
                </fieldset>

                <!-- Group 2: Tokenmic And  Auditing -->
                <fieldset class="border p-4">
                    <legend class="text-lg font-semibold">Tokenmic and auditing</legend>
                    <div class="mb-2">
                        <label for="startDate" class="block">Auditing:</label>
                        <textarea id="auditing" v-model="contract.auditing" class="w-full border rounded p-2" />
                    </div>
                    <div>
                        <label for="endDate" class="block">Tokenmic:</label>
                        <textarea id="tokenmic" v-model="contract.tokenmic" class="w-full border rounded p-2" />
                    </div>
                </fieldset>

                <!-- Group 3: holders -->
                <fieldset class="border p-4">
                    <legend class="text-lg font-semibold">Holders</legend>
                    <div class="mb-2">
                        <label for="Holders" class="block">Holders:</label>
                        <input type="number" id="initialAmount" v-model="contract.holders"
                            class="w-full border rounded p-2" />
                    </div>
                    <div>
                        <label for="Transactions" class="block">Transactions :</label>
                        <input type="number" id="txns" v-model="contract.txns" class="w-full border rounded p-2" />
                    </div>
                    <div>
                        <label for="MarketCap" class="block">MarketCap :</label>
                        <input type="number" id="marketcap" v-model="contract.marketcap"
                            class="w-full border rounded p-2" />
                    </div>
                </fieldset>

                <!-- Group 3: RoadMaps -->
                <fieldset class="border p-4">
                    <legend class="text-lg font-semibold">RoadMaps</legend>
                    <div class="mb-2">
                        <label for="Stage1" class="block">Stage1</label>
                        <input type="text" id="stage1" v-model="contract.stage1" class="w-full border rounded p-2" />
                    </div>
                    <div>
                        <label for="Stage2" class="block">Stage2</label>
                        <input type="text" id="stage2" v-model="contract.stage2" class="w-full border rounded p-2" />
                    </div>
                    <div>
                        <label for="Stage3" class="block">Stage3</label>
                        <input type="text" id="stage3" v-model="contract.stage3" class="w-full border rounded p-2" />
                    </div>
                    <div>
                        <label for="Stage4" class="block">Stage4</label>
                        <input type="text" id="stage4" v-model="contract.stage4" class="w-full border rounded p-2" />
                    </div>
                    <div>
                        <label for="Stage5" class="block">Stage5:</label>
                        <input type="text" id="stage5" v-model="contract.stage5" class="w-full border rounded p-2" />
                    </div>
                </fieldset>


                <!-- Group 5: HowToBuy -->
                <fieldset class="border p-4">
                    <legend class="text-lg font-semibold">HowToBuy</legend>
                    <div>
                        <label for="finalAmount" class="block">How to By Step By Step:</label>
                        <textarea id="description" v-model="contract.howtoby" class="w-full border rounded p-2" />
                    </div>
                </fieldset>

                <!-- ... More groups can be added as needed ... -->

                <button @click="saveContract" class="bg-blue-600 text-white rounded px-4 py-2">Save</button>
            </div>
        </div>

        <!-- Right Layout -->
        <div class="w-1/3 bg-white p-2">

            <div class="h-screen flex flex-col">
                <!-- socail icons -->
                <div class="flex flex-row gap-2">
                    <div v-for="(icon, index) in socialIcons" :key="index" @click="showInput(index)"
                        :class="hasUrlConfiged(index)">
                        <img :class="getImageClass(index)" :src="icon.image" :alt="icon.name" />
                    </div>
                </div>
                <div style="padding: 1vw;"></div>

                <!-- Layout display -->
                <div class="flex h-1/12 items-center justify-center  bg-blue-500 text-white text-2xl">
                    Header
                </div>

                <!-- Row 1  -->
                <div class="flex h-1/6 items-center justify-center bg-green-500 text-white text-2xl">
                    About
                </div>

                <!-- Row 1  -->
                <div class="flex h-1/6 items-center justify-center bg-blue-500 text-white text-2xl">
                    Tokenmic and auditing
                </div>

                <!-- Row 2 -->
                <div class="flex h-1/6 flex items-center justify-center bg-sky-500 text-white text-2xl">
                    Holder
                </div>

                <!-- Row 3 -->
                <div class="flex h-1/6 flex items-center justify-center bg-yellow-500 text-white text-2xl">
                    Roadmap
                </div>

                <!-- Row 4 -->
                <div class="flex h-1/6 items-center justify-center bg-orange-500 text-white text-2xl">
                    HowToBuy
                </div>

                <!-- Row 5 -->
                <div class="flex h-1/12 items-center justify-center h-1/12 bg-red-500 text-white text-2xl">
                    Footer
                </div>
            </div>
        </div>
    </div>
    <!-- JSON富文本展示底部 -->
    <div class="bg-gray-100 p-4">
        <h2 class="text-xl font-semibold mb-2">JSON File</h2>
        <div class="bg-white rounded-lg shadow-lg p-6">
            <div class="p-4">
                <h2 class="text-xl font-semibold mb-2">JSON View</h2>
                <textarea class="w-full border p-2 rounded-lg resize-none" rows="10" v-model="jsonString"></textarea>
            </div>
            <div class="mt-4">
                <h2 class="text-xl font-semibold mb-2">Parsed JSON</h2>
                <pre class="border p-2 rounded-lg bg-gray-200">{{ parsedJson }}</pre>
            </div>
        </div>
    </div>
</template>


     
<script>
import axios from 'axios'

export default {
    data() {
        return {
            socialIcons: [
                { name: "Reddit", image: require("@/assets/images/reddit_svg.svg"), url: '', underline: false },
                { name: "Instagram", image: require("@/assets/images/instagram-brands.svg"), url: '', underline: false },
                { name: "Telegram", image: require("@/assets/images/telegram-brands.svg"), url: '', underline: false },
                { name: "Twitter", image: require("@/assets/images/twitter-brands.svg"), url: '', underline: false },
                { name: "youtube", image: require("@/assets/images/youtube-brands.svg"), url: '', underline: false }
                // Add more social icons here
            ],
            selectedIconIndex: null,
            selectedImageUrl: '',
            jsonData: { title: "Web3 配置" },
            contract: {
                name: '',
                description: '',
                tokenmic: '',
                auditing: '',
                stage1: '',
                stage2: '',
                stage3: '',
                stage4: '',
                stage5: '',
                holders: 0,
                txns: 0,
                marketcap: 0,
                howtobuy: "",
                // ... Add other contract properties here ...
            },
            editedTitle: "",
            editedName: "",
            editedDate: "",
            baseURL: 'https://api.github.com',
            username: 'loserstudy',
            repo: 'jddao',
            accessToken: 'your_personal_access_token',
            filePath: 'path/to/your/file.txt',
            newFileContent: 'This is the updated content for the file.',
            jsonString: "",
        }
    },

    computed: {
        parsedJson() {
            try {
                return JSON.stringify(JSON.parse(this.jsonString), null, 2);
            } catch (error) {
                return "Invalid JSON";
            }
        },
    },

    async created() {
        // 获取JSON文件内容
        try {
            const response = await axios.get('/path/to/your/json-file.json');
            this.jsonData = response.data;
            // 初始化编辑字段
            this.editedTitle = this.jsonData.title;
            this.editedName = this.jsonData.name;
            this.editedDate = this.jsonData.date;
        } catch (error) {
            console.error('Error fetching JSON file:', error.message);
        }
    },

    methods: {
        async saveChanges() {
            // 将编辑后的属性保存回服务器
            try {
                const updatedData = {
                    ...this.jsonData,
                    title: this.editedTitle,
                    name: this.editedName,
                    date: this.editedDate,
                };
                const response = await axios.put('/path/to/your/api/endpoint', updatedData);
                this.jsonData = response.data;
                // 更新编辑字段
                this.editedTitle = this.jsonData.title;
                this.editedName = this.jsonData.name;
                this.editedDate = this.jsonData.date;
            } catch (error) {
                console.error('Error saving changes:', error.message);
            }
        },


        getImageClass(index) {
            return {
                'w-10 h-10 cursor-pointer transition duration-300 transform hover:border-2 hover:-translate-y-1': true,
                'shadow-md': this.socialIcons[index].underline,
            };
        },

        hasUrlConfiged(index) {
            
            if(this.selectedIconIndex==index){
                return {
                        'border-1  border-blue-500': true
                }
            }
            if(this.socialIcons[index].url != ''){
                return {
                        'border-1  border-blue-500': true
                }
            }
        },

        showInput(index) {
            this.socialIcons.forEach((image, i) => {
                if (i === index) {
                    image.underline = !image.underline;
                    this.selectedImageUrl = image.url;
                    this.selectedIconIndex = index;
                } else {                    
                    image.underline = false;
                }
            });
        },

        updateImageUrl(index) {
            this.socialIcons.forEach((image, i) => {
                if (i === index) {
                    image.url = this.selectedImageUrl
                }
            });
        },



        saveContract(){



        }

    },
};
</script>
  